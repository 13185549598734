<template>
  <b-card>
    <b-row>
      <div class="col-12 mt-16">
        <b-form @submit.prevent="addUser" v-if="show">
          <!-- <h2>Car Information</h2> -->
          <div  style="
              background-color: #0010f7;
              height: 40px;
              border-radius: 4px;
            ">
              <h5       style="
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  padding-top : 8px;
                ">
                Company Information
              </h5>
            </div>
            <div class="row" style="margin-top: 20px">
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Company Name:" label-for="company_name">
                  <b-form-input id="company_name" type="text" placeholder="Enter comapany name" v-model="company_name"
                    disabled></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label=" Organization Name:" label-for="owner_name">
                  <b-form-input id="owner_name" type="text" placeholder="Enter organization name" v-model="owner_name"
                    disabled></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label=" Organization Number:" label-for="owner_number">
                  <b-form-input id="owner_number" type="text" placeholder="Enter organization number" v-model="owner_number"
                    disabled></b-form-input>
                </b-form-group>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Document:" label-for="company_document">
                  <div style="margin-left: 3px; margin-bottom: 15px">
                    <img :src="'https://api.driveloop.cionax.com/' + company_document
                      " alt="Document" width="100" height="100" />
                  </div>
                </b-form-group>
              </div>
            </div>
          <div  style="
              background-color: #0010f7;
              height: 40px;
              border-radius: 4px;
            ">
            <h5       style="
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  padding-top : 8px;
                ">
              Car Information
            </h5>
          </div>
          <div class="row" style="margin-top: 20px">
            <!-- <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Company Name:" label-for="vehicle_company">
                <b-form-input id="vehicle_company" v-model="vehicle_company" placeholder="Enter company name"
                  pattern="[A-Za-z]+" title="Please enter only alphabetic characters" disabled></b-form-input>
              </b-form-group>
            </div> -->
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-1" label="Vehicle Name:" label-for="name">
                <b-form-input id="name" type="text" v-model="name" placeholder="Enter vehicle name" autocomplete="off"
                  pattern="[A-Za-z]+" title="Please enter only alphabetic characters" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Description:" label-for="description">
                <b-form-input id="description" v-model="description" placeholder="Enter Description"
                  disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Model Year:" label-for="model_year">
                <b-form-input id="model_year" v-model="model_year" placeholder="Enter Model Year" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Car Model:" label-for="car_model">
                <b-form-input id="car_model" v-model="car_model" placeholder="Enter Car Model" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Car Color:" label-for="car_color">
                <b-form-input id="car_color" v-model="car_color" placeholder="Enter Car Color" disabled></b-form-input>
              </b-form-group>
            </div>
             <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-2"
                label="Car Make:"
                label-for="make"
              >
                <b-form-input
                  id="car_make"
                  v-model="car_make"
                  placeholder="Enter Car Make"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
             <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vehicle Type:" label-for="vehicle_type">
                <b-form-select v-model="vehicle_type" disabled>
                  <option value="">Select Vehicle Type</option>
                  <option>Taxi</option>
                  <option>Office Use</option>
                   <option>Non-Taxi</option>
                  <option>Transport</option>
                  <option>Personal</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number">
                <b-form-input id="registration_number" v-model="registration_number"
                  placeholder="Enter Registration Number" disabled></b-form-input>
              </b-form-group>
            </div>
          <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-2"
                label="Ownership:"
                label-for="type_of_insurance"
              >
                <b-form-select
                  v-model="editedUser.ownership"
                  disabled
                >
                  <option value="">Select Ownership</option>
                  <option>Driveloop</option>
                  <option>Moviro</option>
                  <option>Bilcenter</option>
                  <option>Other</option>
                </b-form-select>
              </b-form-group>
            </div>
          
            <!-- new code  -->
            
            <div class="col-md-4 col-12">
              <h4>Image</h4>
          <div style="display: flex">
            <!-- Display current vehicle images -->
            <div v-for="(image, index) in editedUser.image" :key="index" style="margin-left: 3px; margin-bottom: 15px">
              <img :src="'https://api.driveloop.cionax.com/' + image" alt="Vehicle Image"
                style="max-width: 100px; max-height: 100px" />
            </div>
          </div>
            </div>

              <div class="col-md-4 col-12">
              <h4>Documents</h4>
          <div style="display: flex">
            <!-- Display current vehicle images -->
            <div v-for="(image, index) in editedUser.image" :key="index" style="margin-left: 3px; margin-bottom: 15px">
              <img :src="'https://api.driveloop.cionax.com/' + image" alt="Vehicle Image"
                style="max-width: 100px; max-height: 100px" />
            </div>
          </div>
            </div>
          </div>
          <!-- <h2>Car Expense Information</h2> -->
          <div  style="
              background-color: #0010f7;
              height: 40px;
              border-radius: 4px;
            ">
            <h5       style="
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  padding-top : 8px;
                ">
              Vehicle Report
            </h5>
          </div>
          <div class="row" >
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Last Inspection:" label-for="last_inspection">
                <b-form-input id="last_inspection" v-model="last_inspection" type="date"
                  placeholder="Enter Last Inspection" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Next Inspection:" label-for="next_inspection">
                <b-form-input id="next_inspection" type="date" v-model="next_inspection"
                  placeholder="Enter Next Inspection" disabled></b-form-input>
              </b-form-group>
            </div>
            <!-- <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Insurance:" label-for="insurance">
                <b-form-input id="insurance" v-model="insurance" pattern="[0-9]+"
                  title="Please enter only numeric characters" placeholder="Enter Insurance" disabled></b-form-input>
              </b-form-group>
            </div> -->
            <div v-if="vehicle_type === 'Taxi'" class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Texameter Inspection Date:" label-for="texameter_inspection_date">
                <b-form-input id="texameter_inspection_date" type="date" v-model="texameter_inspection_date"
                  placeholder="Enter Texameter Inspection Date" disabled></b-form-input>
              </b-form-group>
            </div>

            <!-- <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Select Equipment:" label-for="select_equipment">
                <b-form-select v-model="select_equipment" disabled>
                  <option value="">Select Select Equipment</option>
                  <option>Tool Kit</option>
                  <option>Air Pump</option>
                  <option>Tyre Repair Kit</option>
                  <option>Pallet Jack</option>
                  <option>Jump Cable</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Category of Vehicle:" label-for="category_of_vehicle">
                <b-form-select v-model="category_of_vehicle" disabled>
                  <option value="">Select Category Vehicle:</option>
                  <option>Taxi</option>
                  <option>Transport</option>
                  <option>Personal</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Vendor Name:" label-for="vendor_name">
                <b-form-select v-model="vendor_name" pattern="[A-Za-z]+" title="Please enter only alphabetic characters"
                  disabled>
                  <option value="">Select Vendor Name</option>
                  <option>Uber</option>
                  <option>Bolt</option>
                  <option>Taxijakt</option>
                  <option>Daytrip</option>
                  <option>TaxiKurir</option>
                  <option>Others</option>
                </b-form-select>
              </b-form-group>
            </div> -->
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Insurance Company Name:" label-for="insurance_company_name">
                <b-form-input v-model="insurance_company_name" placeholder="Enter Insurance Company Name" disabled>
                  <!-- <option value="">Select Car Type</option> -->
                  <!-- <option>Uber</option> -->
                  <!-- <option>Bolt</option> -->
                </b-form-input>
              </b-form-group>
            </div>
          <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-2"
                label="Type of Insurance:"
                label-for="type_of_insurance"
              >
                <b-form-select
                  v-model="editedUser.type_of_insurance"
                  required
                  disabled
                >
                  <option value="">Select Type of Insurance</option>
                  <option>Full</option>
                  <option>Half</option>
                  <option>Road</option>
                </b-form-select>
              </b-form-group>
            </div>
            
           <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-2"
                label="Timing Type:"
                label-for="timing"
              >
                <b-form-select
                  v-model="editedUser.timing_type"
                  required
                  disabled
                >
                  <option value="">Select Type of timing</option>
                  <option>Timing Chain</option>
                  <option>Timing Belt</option>
                </b-form-select>
              </b-form-group>
            </div>
             <div class="row">
            <div class="col-md-12 col-12">
              <b-form-group
                id="input-group-2"
                label="Select Equipment:"
                label-for="select_equipment"
              >
              <b-form-checkbox-group
                id="select_equipment"
                v-model="editedUser.select_equipment"
                disabled
                :options="['Tool Kit', 'Air Pump', 'Pallet Jack', 'Jump Cable', 'Tire Repair Kit',  'Other']"
              ></b-form-checkbox-group>
              </b-form-group>
            </div> 
        <div class="col-md-12 col-12">
              <b-form-group
                id="input-group-2"
                label="Damage History:"
                label-for="damage_history"
              >
               <b-form-textarea
                v-model="editedUser.damage_history"
                placeholder="Enter Damage History"
                disabled
              >
              </b-form-textarea>
              </b-form-group>

            </div>
          </div>
          </div>

        </b-form>
      </div>

      <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
        <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
      </div>
    </b-row>
  </b-card>
</template>
  
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormSelect,
  BFormInput,
  BFormTextarea
} from "bootstrap-vue";
import axios from "axios";
import code from "../../../components/data-entry/form/code";
import { BToast } from "bootstrap-vue";
export default {
  data() {
    return {
      selectedType: "",
      show: true,
      codeText: code.introduction,
      codeActive: false,
      codeActiveClass: false,
      // Add Vehicle
      name: "",
      vehicle_company: "",
      description: "",
      car_make: "",
      car_model: "",
      car_color: "",
      car_number: "",
      model_year: "",
      registration_number: "",
      timing_type: "",
      type_of_insurance: "",
      equipment: "",
        image: null,
      document: null,
      editedUser: {
        image: [], // Initialize the array here
        document: [], // Initialize the array here
      },      // new code
      vehicle_type: "",
      ownership: "",  
        select_equipment: [],
      damage_history:"",
      last_inspection: "",
      insurance: "",
      texameter_inspection_date: "",
      next_inspection: "",

      category_of_vehicle: "",
      vendor_name: "",
      insurance_company_name: "",
      car_maintenance_feature: "",
      // Car Maintenance
      running: "",
      oil_change: "",
      mileage: "",
      accidental_claim: "",
      other_expense: "",
      owner_name: "",
        owner_number: "",
        company_document: "",
        company_name: "",
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BToast,
    BFormTextarea
  },

  created() {
    // Load the clients data when the component is created
    axios
      .get("B2BIndex")
      .then((response) => {
        this.vehicles = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    const userId = this.$route.params.id;
    axios
      .get(`B2BShow/${userId}`)
      .then((response) => {
        this.editedUser = response.data.data;
        this.name = this.editedUser.name;
        this.vehicle_company = this.editedUser.vehicle_company;
        this.description = this.editedUser.description;
        this.car_make = this.editedUser.car_make;
        this.car_model = this.editedUser.car_model;
        this.car_color = this.editedUser.car_color;
        this.car_number = this.editedUser.car_number;
        this.model_year = this.editedUser.model_year;
        this.registration_number = this.editedUser.registration_number;
        this.equipment = this.editedUser.equipment;
        this.image = this.editedUser.image;
        this.vehicle_type = this.editedUser.vehicle_type;
        this.last_inspection = this.editedUser.last_inspection;
        this.insurance = this.editedUser.insurance;
        this.texameter_inspection_date =
          this.editedUser.texameter_inspection_date;
        this.next_inspection = this.editedUser.next_inspection;
       this.select_equipment = this.editVehicle.select_equipment.split(',');
        this.vendor_name = this.editedUser.vendor_name;
        this.insurance_company_name = this.editedUser.insurance_company_name;
        this.car_maintenance_feature = this.editedUser.car_maintenance_feature;
        this.running = this.editedUser.running;
        this.oil_change = this.editedUser.oil_change;
        this.mileage = this.editedUser.mileage;
        this.accidental_claim = this.editedUser.accidental_claim;
        this.other_expense = this.editedUser.other_expense;
        this.company_document = this.editedUser.company.company_document;
        this.owner_name = this.editedUser.company.owner_name;
        this.owner_number = this.editedUser.company.owner_number;
        this.company_name = this.editedUser.company.company_name;
          this.timing_type = this.editedUser.timing_type;
        this.type_of_insurance = this.editedUser.type_of_insurance;
        this.ownership = this.editedUser.ownership;
        this.damage_history = this.editedUser.damage_history;
        this.document = this.editedUser.document;

        // Depending on the selected option, set the appropriate salary value

        // ... and so on for other properties ...
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },

  methods: {
    showMsgBoxOne() {
      debugger;
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
      // debugger
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onProfilePictureChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Set the selected file to the data property
        this.profile_picture = file;
      }
    },
      downloadImage(imageUrl) {
      const link = document.createElement("a");
      link.href = "https://api.driveloop.cionax.com/" + imageUrl;
      link.download = "image.jpg"; // You can set the desired filename here
      link.target = "_blank"; // Open the link in a new tab
      link.click();
    },
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
  